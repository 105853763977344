import axios from "axios";
import { MANAGELOCALSTORAGE } from "src/Redux/Actions";

const baseUrl = process.env.REACT_APP_Base_Url;

const getHeader = () => {
  const token = MANAGELOCALSTORAGE.getToken();
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return header;
};
export const BASE_CALL = {
  post: async (url, payload) =>
    await axios.post(baseUrl + url, payload, {
      headers: getHeader(),
    }),
  put: async (url, payload) =>
    await axios.put(baseUrl + url + `/${payload.id}`, payload, {
      headers: getHeader(),
    }),
  get: async (url, params) =>
    await axios.get(baseUrl + url, {
      params,
      headers: getHeader(),
    }),
  delete: async (url) =>
    await axios.delete(baseUrl + url, {
      headers: getHeader(),
    }),
  formData: async (url, payload) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseUrl + url,
      data: payload,
      headers: getHeader(),
    };

    let response = await axios.request(config);
    return response;
  },
  formDataPut: async (url, payload) => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: baseUrl + url,
      data: payload,
      headers: getHeader(),
    };

    let response = await axios.request(config);
    return response;
  },
};
