import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import { API_CALL } from "src/services/APICalls";
import { toast } from "react-toastify";
import { Box, Grid, Switch } from "@mui/material";
import { showDate } from "../commonfunctions";
import EditRoadmap from "src/views/Roadmap/EditRoadmap";
import { useNavigate } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Chip } from "@mui/material";
const commonImageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  aspectRatio: "1/1",
};
const cardsCommonStyle = {
  width: "350px",
};
export default function NftCard({
  item,
  reloadIt,
  setEditModal,
  openDeleteModal,
}) {
  const navigate = useNavigate();

  const deleteCard = async () => {
    openDeleteModal(item._id);
  };
  return (
    <Card
      sx={{
        ...cardsCommonStyle,
        maxWidth: "300px",
        width: "100%",
        "&:hover": {
          boxShadow: 6,
          transition: "box-shadow 0.3s ease-in-out",
        },
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid #000e32",
      }}
    >
      {/* Image Container */}
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src={item.image}
          alt={item.title}
          sx={{
            width: "100%",
            height: 200,
            objectFit: "cover",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        />
        {item?.category?.name && (
          <Chip
            label={item.category.name}
            sx={{
              position: "absolute",
              top: 12,
              right: 12,
              bgcolor: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "blur(4px)",
            }}
          />
        )}
      </Box>

      {/* Content */}
      <CardContent sx={{ p: 3, py: 2 }}>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 600 }}
        >
          {item.title}
        </Typography>
      </CardContent>

      {/* Actions */}
      <CardActions sx={{ px: 3, pb: 2, pt: 0 }}>
        <Grid container spacing={1}>
          <Grid item textAlign="right">
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => navigate("/edit-gallery/" + item._id)}
              color="primary"
            >
              Edit
            </Button>
          </Grid>
          <Grid item xs={3} textAlign="left">
            <Button
              variant="contained"
              size="small"
              startIcon={<DeleteIcon />}
              onClick={deleteCard}
              color="error"
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
export const CommunityCard = ({
  item,
  openDeleteModal,
  reloadIt,
  setEditModal,
}) => {
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update(
        { showInHomeSlider: !item.showInHomeSlider },
        item._id
      );
      if (data.success) {
        item.showInHomeSlider
          ? toast.success("NFT has been removed from Slider")
          : toast.success("Nft has been added to Slider");
        reloadIt();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while updating NFT");
    }
  };
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id);
      // const { data } = await API_CALL.Community.delete(item._id)
      // if (data.success) {

      //   toast.success("NFT successfully Deleted")
      //   reloadIt()
      // }
    } catch (error) {
      console.log(error);
      toast.error("Error while updating NFT");
    }
  };
  return (
    <Card
      sx={{ ...cardsCommonStyle, borderRadius: "10px" }}
      style={{ border: "1px solid #ececec" }}
    >
      <Box>
        <img src={item.image} alt={item.title} style={commonImageStyle} />
      </Box>
      <CardContent style={{ padding: "5px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography gutterBottom variant="h5" component="div">
            {item.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="gray">
            {item?.category?.name}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        {/* <CollectionsBookmarkIcon  color={item?.showInHomeSlider?"primary":""} style={{cursor:"pointer"}} onClick={manageBanner} /> */}
        <Button size="small" color="error" onClick={deleteCard}>
          {" "}
          Delete
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => setEditModal({ open: true, data: item })}
        >
          {" "}
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};
export const PartnerCard = ({
  item,
  openDeleteModal,
  reloadIt,
  setEditModal,
}) => {
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update(
        { showInHomeSlider: !item.showInHomeSlider },
        item._id
      );
      if (data.success) {
        item.showInHomeSlider
          ? toast.success("NFT has been removed from Slider")
          : toast.success("Nft has been added to Slider");
        reloadIt();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while updating NFT");
    }
  };
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id);
      // const { data } = await API_CALL.Partner.delete(item._id)
      // if (data.success) {

      //   toast.success("NFT successfully Deleted")
      //   reloadIt()
      // }
    } catch (error) {
      console.log(error);
      toast.error("Error while updating NFT");
    }
  };
  return (
    <Card
      sx={{
        ...cardsCommonStyle,
        maxWidth: "100% ",
        minWidth: "250px",
        width: "100%",
        "&:hover": {
          boxShadow: 6,
          transition: "box-shadow 0.3s ease-in-out",
        },
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid #000e32",
      }}
    >
      {/* Image Container */}
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src={item.image}
          alt={item.title}
          sx={{
            width: "100%",
            height: 200,
            objectFit: "cover",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        />
      </Box>

      {/* Content */}
      <CardContent sx={{ p: 3, py: 2 }}>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 500 }}
          color={"gray"}
        >
          {item?.category}
        </Typography>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 600 }}
        >
          {item.title}
        </Typography>
      </CardContent>

      {/* Actions */}
      <CardActions sx={{ pb: 2, pt: 0 }}>
        <Grid display={"flex"} justifyContent={"center"}  sx={{display:"flex", gap:"20px", width:"100%"}}>
          <Grid item display={"flex"} justifyContent={"center"}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setEditModal({ open: true, data: item })}
              color="primary"
            >
              Edit
            </Button>
          </Grid>
          <Grid item xs={3} textAlign="left">
            <Button
              variant="contained"
              size="small"
              startIcon={<DeleteIcon />}
              onClick={deleteCard}
              color="error"
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
export const SliderCard = ({
  item,
  reloadIt,
  clickEdit,
  openDeleteModal = () => {},
}) => {
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update(
        { showInHomeSlider: !item.showInHomeSlider },
        item._id
      );
      if (data.success) {
        item.showInHomeSlider
          ? toast.success("NFT has been removed from Slider")
          : toast.success("Nft has been added to Slider");
        reloadIt();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while updating NFT");
    }
  };
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id);
      // const {data} =await API_CALL.Slider.delete(item._id)
      // if(data.success) {

      //     toast.success("NFT successfully Deleted")
      //     reloadIt()
      // }
    } catch (error) {
      console.log(error);
      toast.error("Error while updating NFT");
    }
  };
  return (
    <Card
      sx={{
        maxWidth: "300px",
        width: "100%",
        "&:hover": {
          boxShadow: 6,
          transition: "box-shadow 0.3s ease-in-out",
        },
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid #000e32",
      }}
    >
      {/* Image Container */}
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src={item.image}
          alt={item.title}
          sx={{
            width: "100%",
            height: 200,
            objectFit: "cover",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        />
        {item.category && (
          <Chip
            label={item.category}
            sx={{
              position: "absolute",
              top: 12,
              right: 12,
              bgcolor: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "blur(4px)",
            }}
          />
        )}
      </Box>

      {/* Content */}
      <CardContent sx={{ p: 3, py: 2 }}>
        <Typography
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ fontWeight: 600 }}
        >
          {item.title}
        </Typography>
      </CardContent>

      {/* Actions */}
      <CardActions
        sx={{ px: 3, pb: 2, pt: 0, justifyContent: "flex-end", gap: 1 }}
      >
        <Button
          variant="outlined"
          size="small"
          startIcon={<EditIcon />}
          onClick={clickEdit}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={deleteCard}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};
export const RoadmapCard = ({
  item,
  onclickEdit,
  openDeleteModal,
  reloadIt,
}) => {
  const deleteCard = async () => {
    openDeleteModal(item._id);
    // try {
    //   const { data } = await API_CALL.Roadmap.delete(item._id)
    //   if (data.success) {

    //     toast.success("NFT successfully Deleted")
    //     reloadIt()
    //   }
    // } catch (error) {
    //   console.log(error)
    //   toast.error("Error while updating NFT")
    // }
  };
  return (
    <Card
      sx={{
        ...cardsCommonStyle,
        maxWidth: "280px",
        width: "100%",
        "&:hover": {
          boxShadow: 6,
          transition: "box-shadow 0.3s ease-in-out",
        },
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid #000e32",
        height: "100%",
      }}
    >
      {/* Image Container with 1:1 Aspect Ratio */}
      <div
        style={{
          aspectRatio: "1/1",
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box>
          <img
            src={item.image}
            alt={item.title}
            style={{
              ...commonImageStyle,
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          />
        </Box>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingY: 2,
        }}
      >
        {/* Content */}
        <CardContent style={{ padding: "5px 20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color="gray"
              fontSize={13}
              sx={{ opacity: 0.8 }}
            >
              {showDate(item?.date)}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontWeight: 500,
                fontSize: "1.1rem",
              }}
            >
              {item.title}
            </Typography>
          </div>
        </CardContent>
        {/* Actions */}
        <CardActions
          style={{
            padding: "0 10px",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <Button
            size="small"
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={deleteCard}
          >
            Delete
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => onclickEdit(item)}
          >
            Edit
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
export const ArtistCard = ({
  openViewModal,
  openDeleteModal,
  item,
  onclickEdit,
  reloadIt,
}) => {
  const [openEditModal, setopenEditModal] = React.useState({
    show: false,
    data: {},
  });
  const manageBanner = async () => {
    try {
      const { data } = await API_CALL.collection.update(
        { showInHomeSlider: !item.showInHomeSlider },
        item._id
      );
      if (data.success) {
        item.showInHomeSlider
          ? toast.success("NFT has been removed from Slider")
          : toast.success("Nft has been added to Slider");
        reloadIt();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while updating NFT");
    }
  };
  const deleteCard = async () => {
    try {
      openDeleteModal(item._id);
      // const { data } = await API_CALL.Artist.delete(item._id)
      // if (data.success) {

      //   toast.success("NFT successfully Deleted")
      //   reloadIt()
      // }
    } catch (error) {
      console.log(error);
      toast.error("Error while Deleting NFT");
    }
  };
  return (
    <Card
      sx={{
        ...cardsCommonStyle,
        borderRadius: "10px",
        "&:hover": {
          boxShadow: 3,
          transition: "box-shadow 0.3s ease-in-out",
        },
      }}
      style={{ border: "1px solid #ececec" }}
    >
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={item.image}
          alt={item.title}
          style={{
            ...commonImageStyle,
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Box>

      <CardContent style={{ padding: "5px 20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="gray"
            fontSize={13}
            sx={{
              opacity: 0.75,
            }}
          >
            {showDate(item?.date)}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontSize: "1.15rem",
              fontWeight: 500,
            }}
          >
            {item.title}
          </Typography>
        </div>
      </CardContent>

      <CardActions
        style={{
          padding: "10px 10px",
          display: "flex",
          justifyContent: "center",
          gap: "8px",
          marginBottom: "8px",
        }}
      >
        <Button
          size="small"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={deleteCard}
          variant="outlined"
        >
          Delete
        </Button>
        <Button
          size="small"
          color="primary"
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => onclickEdit(item)}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};
