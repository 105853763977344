import React, { useEffect, useState } from "react";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "src/components/shared/DashboardCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { API_CALL } from "src/services/APICalls";
import { showDate } from "src/components/commonfunctions";
import BackDropLoader from "src/components/CommonComponents/BackdropLoader";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function ManageWhitelistWaitlist() {
  const [tabValue, setTabValue] = useState(0);
  const [whitelistData, setWhitelistData] = useState([]);
  const [countData, setCountData] = useState(null);
  const [waitlistData, setWaitlistData] = useState([]);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const reloadIt = () => setReloadPage(!reloadPage);

  useEffect(() => {
    fetchData();
    
  }, [tabValue, reloadPage]);

  const fetchData = async () => {
    try {
      setShowBackDrop(true);
      if (tabValue === 1) {
        // Fetch whitelist data
        const {data} = await API_CALL.whitelist.getWhitelist(new URLSearchParams({count:true}));
        if (data.success) {
          setWhitelistData(data.data.list);
          setCountData(data.data.count)
    
        }
      } else {
        // Fetch waitlist data
       
        const {data} = await API_CALL.whitelist.getWaitlist(new URLSearchParams({count:true}));
        if (data.success) {
        
          setWaitlistData(data.data.list);
          setCountData(data.data.count)
        }
      }
      setShowBackDrop(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
      setShowBackDrop(false);
    }
  };


  const handleApprove = async (id) => {
    try {
      setShowBackDrop(true);
      const { data } = await API_CALL.whitelist.approve(id);
      if (data.success) {
        toast.success("Successfully approved");
        reloadIt();
      }
      setShowBackDrop(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to approve");
      setShowBackDrop(false);
    }
  };

  const ListTable = ({ data, isWaitlist }) => {
    const title = {
      fontWeight: "bold",
      fontSize: "17px",
    };

    return (
      <TableContainer component={Paper} sx={{ maxHeight: "70vh" }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={title}>Sr No</TableCell>
              <TableCell style={title} align="center">
                Name
              </TableCell>
              <TableCell style={title} align="center">
                Email
              </TableCell>
              <TableCell style={title} align="center">
                Wallet Address
              </TableCell>
              <TableCell style={title} align="center">
                Created At
              </TableCell>
              {isWaitlist && (
                <TableCell style={title} align="center">
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            { data?.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
               
                <TableCell>{index + 1}</TableCell>
                <TableCell align="center">
                  {row.firstName ? `${row.firstName} ${row.lastName}` : "-"}
                </TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.walletAddress}</TableCell>
                <TableCell align="center">{showDate(row.createdAt)}</TableCell>
                {isWaitlist && (
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleApprove(row._id)}
                    >
                      Approve
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              padding: "20px",
              borderTop: "1px solid",
            }}
          >
            No records found!
          </div>
        )}
      </TableContainer>
    );
  };

  return (
    <PageContainer title="Whitelist & Waitlist Management" description="">
      <DashboardCard title="Manage Lists">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Tabs Section */}
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Waitlist" />
            <Tab label="Whitelist" />
          </Tabs>

          {/* Total User Section */}
          <Box sx={{ paddingRight: 2 }}>Total User:{countData}</Box>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <ListTable data={waitlistData} isWaitlist={true} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ListTable data={whitelistData} isWaitlist={false} />
        </TabPanel>

        {showBackDrop && <BackDropLoader />}
      </DashboardCard>
    </PageContainer>
  );
}

export default ManageWhitelistWaitlist;
